@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
  }
  .scrollbar::-webkit-scrollbar {
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 19px;
    background-color: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 19px;
    opacity: 0.8;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #e6e6e6;
  }
}

@layer base {
  @font-face {
    font-family: "Hanken Grotesk";
    src: url(/public/Font-family/Hanken_Grotesk/HankenGrotesk-VariableFont_wght.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-Black.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-Bold.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-ExtraBold.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-ExtraLight.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-Light.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-Medium.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-Regular.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-SemiBold.ttf), url(/public/Font-family/Hanken_Grotesk/static/HankenGrotesk-Thin.ttf) format('truetype') ;
  }
  html,body {
    font-family: "Hanken Grotesk";
  }
}
