.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* height */
::-webkit-scrollbar {
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #e5e7eb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #4244488c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #424448;
}

.tab-wrap {
  @media screen and (max-width: 1400px) {
    flex-wrap: wrap !important;
  }
}

.common-tel-phone .react-tel-input .flag-dropdown.invalid-number,
.common-tel-phone .react-tel-input .flag-dropdown {
  border: none;
  background: none;
}
.common-tel-phone .react-tel-input {
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #d1d5db;
  padding: 10.5px;
  width: 100%;
  background: #ffffff;
  border-radius: 0.5rem;
}

.error-tel-phone .react-tel-input {
  border: 1px solid #ef4444 !important;
}

.common-tel-phone .react-tel-input .form-control {
  width: 100%;
  border: none !important;
  background: none !important;
}

.common-tel-phone .react-tel-input .country-list .search-box {
  width: unset;
  padding: 3px 8px 5px !important;
}

.common-manual-upload .react-tel-input .flag-dropdown .selected-flag {
  padding: 0px;
  width: 28px;
}

.common-manual-upload .react-tel-input .form-control {
  padding-left: 35px;
}

.common-manual-upload .react-tel-input .country-list {
  font-size: 12px;
}

.common-manual-upload .react-tel-input .country-list .country {
  padding: 3px 10px;
}

.company-upload .react-tel-input {
  padding: 6.5px;
}

.detail-table .react-tel-input {
  padding: 0px;
}

.detail-table .react-tel-input .form-control {
  padding-left: 40px;
  height: 28px;
}

.detail-table .react-tel-input .country-list {
  font-size: 12px;
  width: 200px;
}

.detail-table .react-tel-input .country-list .country {
  padding: 3px 10px;
}
